import  Banner from '../components/Banner';
import WishLists from '../components/WishLists';
import React from 'react';
import Place from '../components/Place';
import churchIcon from '../assets/images/church.png';
import venueIcon from '../assets/images/venue.png';
import ImageGrid from '../components/ImagesGrid';
import Countdown from '../components/Countdown';
import Asistencia from '../components/Asistencia';
import Presentacion from '../components/Presentacion';

// CSS
import '../assets/styles/Home.css';

const Home = () => {
    const weddingDate = '2025-03-17T13:00:00';
    const places = [
        {
            location : "Templo de Nuestra Señora del Perpetuo Socorro",
            time : "La Cima, 45134 Zapopan, Jal.",
            icon: churchIcon, 
            title: "LUNES 17 | 13:00 HRS.", 
            evento: "CEREMONIA RELIGIOSA",
            ubicacion_link: "https://maps.app.goo.gl/SK6By6fQcNbQX6v3A"
        },
        {
            location: "Hacienda San Clemente",
            time: "Av. General Ramón Corona 651, La Mojonera 45200 Zapopan, Jal.",
            icon: venueIcon,
            title: "LUNES 17 | 15:00 HRS.",
            evento: "RECEPCIÓN",
            ubicacion_link: "https://maps.app.goo.gl/TqbJP4g4qZKqCoox6"
        }
    ]

    const link_confirmar = "https://forms.gle/5wT68VvvCjGBV1kR8/";

    return <React.Fragment> 
        <Banner />
        <div className='inicio'>

        <Presentacion/>
        
        <Countdown weddingDate={weddingDate} />
        
        <ImageGrid />

        
        <h2 className='itinerario'>ITINERARIO</h2>

        <div className='place_div'>

            {places.map((place, index) => (
            <Place key={index} data={place} />
            ))}

        </div>

        <Asistencia link_confirmar={link_confirmar}/>
        <WishLists />

        </div>
        {/* AQUI SE AGREGAN LOS COMPONENTES*/}
    </React.Fragment>
}

export default Home;