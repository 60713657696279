import React from 'react';
import './styles/ImageGrid.css';

const ImageGrid = () => {
  const images = [
    'https://marianasergio.my.canva.site/invitacion/images/09c06f5f3c6d8e4e6747d15a5ed629c2.jpg',
    'https://marianasergio.my.canva.site/invitacion/images/c43d99eb540fd6d43abd48ab66e9d0bc.jpg',
    'https://marianasergio.my.canva.site/invitacion/images/12e6e62057821e9da23239c7566f49c0.jpg',
    'https://marianasergio.my.canva.site/invitacion/images/209d3d94b8e2b5ad511fd6c86a969ee3.jpg',
    'https://marianasergio.my.canva.site/invitacion/images/26084177f0729ec70acb5cd987dea1bf.jpg',
    'https://marianasergio.my.canva.site/invitacion/images/6073b78cf5a62c578c457a14582f0157.jpg',
    'https://marianasergio.my.canva.site/invitacion/images/e9283490a8eec341a6e687e0511a1f68.jpg',
    'https://marianasergio.my.canva.site/invitacion/images/e067b4d7b646a98a01f188da1fa74ff5.jpg',
    
  ];

  return (
    <div className="image-grid">
      {images.map((image, index) => (
        <div className={`image-item ${index === 0 ? 'span' : ''}`} key={index}>
          <img src={image} alt={`Image ${index + 1}`} />
        </div>
      ))}
    </div>
  );
};

export default ImageGrid;
