import React from 'react';
import './styles/Asistencia.css'; // Import CSS for styling


const Asistencia = ({link_confirmar}) => {
  const link = link_confirmar;

  return (
    <section className="asistencia">
      <div className="line1"></div>
      <h2>CONFIRMA TU ASISTENCIA</h2>

      <div className="text-container-asistencia">
      <p>Momentos tan especiales como este se vuelven inolvidables al compartirlos con personas como tú</p>
      
      <a href={link_confirmar} target="_blank" rel="noopener noreferrer">
            <button
                style={{
                    backgroundColor: 'white',
                    color: 'black',
                    border: '2px solid black',
                    padding: '10px 20px',
                    cursor: 'pointer',
                    fontSize: '16px',
                    borderRadius: '0px',
                    transition: 'background-color 0.3s ease',
                    paddingTop: '10px',
                }}
                onMouseEnter={(e) => e.target.style.backgroundColor = 'lightgray'}
                onMouseLeave={(e) => e.target.style.backgroundColor = 'white'}
            >
                Confirmar
            </button>
        </a>
        </div>

        <div className="line"></div>
    </section>
  );
};

export default Asistencia;