import React from 'react';
import './styles/Banner.css'; // Importing the CSS file
import bannerImage from '../assets/images/banner.jpeg'; // Import the local image


const image = "https://marianasergio.my.canva.site/invitacion/images/057f8a76b1689f10d10adcdd6120b005.jpg";

const Banner = () => {
  return (
    <div className="background-section" style={{ backgroundImage: `url(${image})` }}>
      <div className="overlay">
        <h1 className="title">Mariana & Sergio</h1>
        <h2 className="subtitle">Lunes, 17 de marzo del 2025</h2>
      </div>
    </div>
  );
};

export default Banner;