import React from 'react';
import './styles/StoreLink.css'; // Import CSS for styling

const StoreLink = ({ store }) => {
  const { name, image, link } = store;

  return (
    <a href={link} target="_blank" rel="noopener noreferrer" className="store-link">
      <img src={image} alt={name} className="store-image" />
      <p>{name}</p>
    </a>
  );
};

export default StoreLink;