import React from 'react';
import StoreLink from './StoreLink';
import './styles/WishList.css'; // Import CSS for styling
import Amazon from '../assets/images/amazon.png';
import Liverpool from '../assets/images/liverpool.png';

const WishLists = () => {
  const stores = [
    {
      name: 'Amazon',
      image: Amazon, // Path to the Amazon logo image
      link: 'https://www.amazon.com.mx/wedding/registry/1L9QRQQ8ZIGBM',
    },
    {
      name: 'Liverpool',
      image: Liverpool, // Path to the Target logo image
      link: 'https://mesaderegalos.liverpool.com.mx/milistaderegalos/51525061',
    },
  ];

  return (
    <section className="mesa-de-regalo">
      <h2>MESA DE REGALOS</h2>
      <p>Gracias por formar parte de nuestro inicio como familia</p>
      <div className="store-links">
        {stores.map((store, index) => (
          <StoreLink key={index} store={store} />
        ))}
      </div>
    </section>
  );
};

export default WishLists;