import React from 'react';
import './styles/Place.css'; // Import the CSS file
 // Import the church icon image

const Place = ({data}) => {
  const {location, time, icon, title, evento, ubicacion_link} = data;

  return (
    <div className="container">
      
      <div className="image-container">
        <img src={icon} alt="Church" className="church-icon" />
      </div>

      <div className="text-container">
        
        <h3 className="header">{title}</h3>
        <h2>{evento}</h2>
        <p className="detail">
           {location}
        </p>
        <p className="detail">
           {time}
        </p>
      
      <a href={ubicacion_link} target="_blank" rel="noopener noreferrer">
            <button
                style={{
                    backgroundColor: 'white',
                    color: 'black',
                    border: '2px solid black',
                    padding: '10px 20px',
                    cursor: 'pointer',
                    fontSize: '16px',
                    borderRadius: '0px',
                    transition: 'background-color 0.3s ease'
                }}
                onMouseEnter={(e) => e.target.style.backgroundColor = 'lightgray'}
                onMouseLeave={(e) => e.target.style.backgroundColor = 'white'}
            >
                Ubicación
            </button>
        </a>
        </div>
    </div>
  );
};

export default Place;