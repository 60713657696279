import React, { useState, useEffect } from 'react';
import './styles/Countdown.css';

const Countdown = ({ weddingDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(weddingDate) - +new Date(); // Calculate the difference between the current date and wedding date
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Cleanup the timer on component unmount
  }, [weddingDate]);

  return (
    <div className="countdown">
      <h1>Cuenta regresiva</h1>
      <div className="time">
        <div className="time-box">
          <span className="number">{timeLeft.days || '0'}</span>
          <span className="label">Dias</span>
        </div>
        <div className="time-box">
          <span className="number">{timeLeft.hours || '0'}</span>
          <span className="label">Horas</span>
        </div>
        <div className="time-box">
          <span className="number">{timeLeft.minutes || '0'}</span>
          <span className="label">Minutos</span>
        </div>
        <div className="time-box">
          <span className="number">{timeLeft.seconds || '0'}</span>
          <span className="label">Segundos</span>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
