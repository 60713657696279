import React from 'react';
import './styles/Presentacion.css'; // Import CSS for styling

const Presentacion = () => {
    
  
    return (
      <section className="presentacion">
  <h4>CON LA BENDICIÓN DE DIOS Y DE NUESTROS PADRES</h4>

  {/* Parent container holding both left and right parent sections */}
  <div className="parents-container">
    <div className="papas_s">
    <h4>Gloria Ramirez Haro</h4>
    <h4>Sergio Velazquez Rodriguez</h4>
    </div>

    <div className="papas_m">
      <h4>Teresa Grave Herena</h4>
      <h4>Marco Polo Gomez Carrillo</h4>
    </div>
  </div>

  <h1 className='titulo_p'>Mariana y Sergio</h1>

    <h4 id='mensaje'>DESEAMOS COMPARTIR CON USTEDES LA ALEGRÍA DE NUESTRO MATRIMONIO</h4>
    <div className="fecha-container">
  
      <span>LUNES</span>
      <div className="linea"></div>
      <span className='numero'>17</span>
      <div className="linea"></div>
      <span>MARZO</span>
      
    </div>

  <div className="line"></div>
</section>

    );
  };


export default Presentacion;